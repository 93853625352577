.centeredCardPage_container {
  height: 100vh;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
}

.centeredCardPage_card {
  min-width: 50%;
  width: auto;
  height: auto;
  padding: 3em;
  background-color: white;
  border-radius: 10px;
}
