@import "src/index";

.tenantList_heading {
  text-align: center;
  padding-bottom: 1em;
  color: grey;
}

.tenantList_addNewAccountBtn {
  margin-top: 1em;
}