.refreshableSearchablePageableTable_footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.refreshableSearchablePageableTable_pageNumberContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 75%;
}

.refreshableSearchablePageableTable_sizeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.refreshableSearchablePageableTable_footer_text {
  font-size: 1em;
  line-height: 2;
  white-space: nowrap;
}

.refreshableSearchablePageableTable_footer_btn {
  font-size: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.refreshableSearchablePageableTable_footer_pageInput {
  font-size: 1em;
  margin-right: 0.5em;
  width: 3em;
}

.refreshableSearchablePageableTable_footer_showDropDown {
  font-size: 1em;
  margin-left: 1em;
}

