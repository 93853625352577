.productForm_card {
  margin-bottom: 1em;
}

.productForm_card_sectionContainer {
  border-bottom: 1px solid #dee2e6;
}

.productForm_card_form {

}