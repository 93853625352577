.refreshableSearchableTable_actions {
  margin-bottom: 1em;
  float: right;
  width: 100%;
}

.refreshableSearchableTable_searchInput {
  width: 50%;
}

.refreshableSearchableTable_categoryRow {
  cursor: pointer;
}