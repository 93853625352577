.customerForm_actionBtnHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 0.5em;
}

.customerForm_card_form {
  padding: 0 1em;
}

.customerForm_deleteIcon {
  color: red;
  height: 30px;
}