@import "src/index";

.customer-name {
  @extend .heading;
  font-size: 1.2em;
}

.customer-address {
  list-style: none;
  margin: 0;
  padding: 0;
}