.heading {
  font-size: 2em;
  text-transform: uppercase;
  color: #adadad;
}

.subheading {
  font-size: 1.3em;
  text-transform: uppercase;
  color: #adadad;
}

.cardHeading {
  @extend .heading;
  font-size: 1.5em;
}

@import "~bootstrap/scss/bootstrap";