@import "src/index";

.accountOption_container {
  margin-top: 1em;
  padding: 0.5em 2em;
  border-radius: 7px;


  -moz-box-shadow: 0px 8px 8px #f3f3f3;
  -webkit-box-shadow: 0px 8px 8px #f3f3f3;
  box-shadow: 0px 8px 8px #f3f3f3;
}

.accountOption_container:hover {
  background-color: #f7f7f7;
  cursor: pointer;
  border-bottom: 1px solid $primary;
}

.accountOption_header {

}

.accountOption_subheading {

}